export default {
  /**
   * Phân trang cho 1 khối dữ liệu
   * @param {Number} length - Độ dài dữ liệu cần phân trang
   * @param {Number} perPage - Số dữ liệu mỗi trang
   * @returns {Number} - Số trang được phân
   */
  setPages(length, perPage) {
    if (length === 0) return;
    let pages = [];
    let numberOfPages = Math.ceil(length / perPage);
    for (let index = 1; index <= numberOfPages; index++) {
      pages.push(index);
    }
    return pages;
  },
  /**
   * Phân dữ liệu ra từng trang
   * @param {Array} news - Dữ liệu
   * @param {Number} page - Trang hiện tại
   * @param {Number} perPage - Số dữ liệu mỗi trang
   * @returns {Array} - Dữ liệu được phân
   */
  paginate(news, page, perPage) {
    let from = page * perPage - perPage;
    let to = page * perPage;
    return news.slice(from, to);
  },
  range(min, max) {
    let numbers = [];
    for (let index = min; index <= max; index++) {
      numbers.push(index);
    }
    return numbers;
  },
};
