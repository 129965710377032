import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { i18n } from "./i18n";
import "./plugins/axios";
import "./plugins/day";
import "./plugins/alert";
import "./plugins/filters";
import helpers from "./helpers";

import store from "./store";
Vue.prototype.$helpers = helpers;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
