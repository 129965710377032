export default [
  {
    path: "/",
    name: "index",
    component: () => import("../views/index"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("../views/introduction"),
  },
  {
    path: "/news",
    component: () => import("../views/news/Master.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/news/index"),
      },
      {
        path: ":id",
        component: () => import("../views/news/id"),
      },
    ],
  },
  {
    path: "/lecturer",
    component: () => import("../views/lecturer/Master.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/lecturer/index"),
      },
      {
        path: ":id",
        component: () => import("../views/lecturer/id"),
      },
    ],
  },
  {
    path: "/academic",
    name: "academic",
    component: () => import("../views/academic"),
  },
  {
    path: "/research",
    component: () => import("../views/research/Master.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/research/index"),
      },
      {
        path: ":id",
        component: () => import("../views/research/id"),
      },
    ],
  },
  {
    path: "/alumni",
    component: () => import("../views/research/Master.vue"),
    children: [
      {
        path: "",
        component: () => import("../views/alumni/index"),
      },
    ],
  },

  { path: "*", component: () => import("../views/404") },
];
