import Vue from "vue";

const stripHTML = (value) => {
  let refinedStr = value.replace(/(<([^>]+)>)/gi, "");
  return refinedStr.replace(/&nbsp;/gi, " ");
};

const limitText = (value, char = 50) => {
  let refinedStr = value.split(" ");
  if (refinedStr.length <= char) return value;
  return refinedStr.splice(0, char).join(" ") + "...";
};

Vue.filter("stripHTML", stripHTML);
Vue.filter("limitText", limitText);
